import { alertApi } from '@/services/api';

export default {
  namespaced: true,
  actions: {
    //get filters list and update store - for refresh purposes
    GET_ALERTS: async ({ commit }) => {
      await alertApi
        .getAlerts()
        .then((resp) => {
          if (resp && resp.data) {
            return resp.data;
          }
        })
        .catch((error) => {
          console.log('Error retrieving alerts list' + error);
          return false;
        });
      return await alertApi
        .getAlerts()
        .then((resp) => {
          if (resp && resp.data) {
            return resp.data;
          }
        })
        .catch((error) => {
          console.log('Error retrieving alerts list' + error);
          return false;
        });
    },
    SAVE_ALERT: async ({ commit }, data) => {
      return await alertApi
        .modifyAlertSettings(data.id, data.conditions)
        .then((resp) => {
          return !!(resp.request && resp.request.status === 200);
        })
        .catch((error) => {
          console.log('Error saving alert' + error);
          return false;
        });
    },
    RESET_ALERT: async ({ commit }, id) => {
      return await alertApi
        .resetAlert(id)
        .then((resp) => {
          if (resp.request && resp.request.status === 200) {
            return resp.data;
          }
          return false;
        })
        .catch((error) => {
          console.log('Error resetting alert' + error);
          return false;
        });
    },
    SET_STATUS: async ({ commit }, data) => {
      return await alertApi
        .setStatus(data.id, data.status)
        .then((resp) => {
          return !!(resp.request && resp.request.status === 200);
        })
        .catch((error) => {
          console.log('Error changing status' + error);
          return false;
        });
    }
  }
};
