import { deliverySummariesApi, userApi, dqApi } from '@/services/api';
import { FilterType } from '@/typings/enums';
import { DeliveryEditSpec, DQNewStageEvent, TableColumn } from '@/typings';
import { DeliverySummariesExportRequestBody, DeliverySummaryBody, Pageable } from '@/services/model/vista';
import { FollowedDelivery } from '@/services/model/user';
import { AxiosError, isCancel } from 'axios';

const HIDE_SAVED_DQ_CHANGES_DURATION = 1000 * 60 * 60 * 12; // ms

export const state = () => ({
  shipments: [] as Array<any>,
  shipmentEdits: {} as Record<string, Array<{ shipmentEdit: any; dqApiDto: any }>>,
  savedShipmentEdits: {} as Record<string, number>,
  totalCountForFilter: 0,
  fetchingShipments: false,
  fetchShipmentController: new AbortController(),
  pageSize: 20,
  page: 0,
  sort: {
    sortStr: null as string | null,
    field: null,
    order: null
  }
});

type ShipmentsState = ReturnType<typeof state>;

const getters = {
  isEdited: (state: ShipmentsState) => (deliveryNumber: string, col: TableColumn) => {
    const propertyName = col.property ? col.property : col.field;
    return deliveryNumber in state.shipmentEdits && state.shipmentEdits[deliveryNumber].some((edit) => propertyName in edit.shipmentEdit);
  },
  numberOfEdits(state: ShipmentsState) {
    let sum = 0;
    for (const edit of Object.values(state.shipmentEdits)) {
      sum += (edit as Array<any>).length;
    }
    return sum;
  },
  getShipments(state: ShipmentsState, _, rootState) {
    const isDQFilterActivated = !!rootState.filters.selectedConditions[FilterType.DQ_FILTER]?.length;
    const now = Date.now();
    return state.shipments
      .filter((delivery) =>
        isDQFilterActivated
          ? !(delivery.id in state.savedShipmentEdits) || state.savedShipmentEdits[delivery.id] + HIDE_SAVED_DQ_CHANGES_DURATION < now
          : true
      )
      .map((delivery) => {
        if (delivery.deliveryNumber in state.shipmentEdits) {
          const changesToApply = state.shipmentEdits[delivery.deliveryNumber].map((edit) => edit.shipmentEdit);
          return Object.assign({}, delivery, ...changesToApply);
        } else {
          return delivery;
        }
      });
  },
  totalCount(state: ShipmentsState, _, rootState) {
    const isDQFilterActivated = !!rootState.filters.selectedConditions[FilterType.DQ_FILTER]?.length;
    const now = Date.now();
    const numberOfPassingShipments = state.shipments.filter((delivery) =>
      isDQFilterActivated
        ? !(delivery.id in state.savedShipmentEdits) || state.savedShipmentEdits[delivery.id] + HIDE_SAVED_DQ_CHANGES_DURATION <= now
        : true
    ).length;
    const numberOfFilteredOutShipments = state.shipments.length - numberOfPassingShipments;
    return state.totalCountForFilter - numberOfFilteredOutShipments;
  }
};

const mutations = {
  setShipments(state: ShipmentsState, data) {
    state.shipments = data.deliverySummaries;
    state.totalCountForFilter = data.totalCount;
    state.fetchingShipments = false;
  },
  setShipmentsEmpty(state: ShipmentsState) {
    state.shipments = [];
    state.totalCountForFilter = 0;
    state.fetchingShipments = false;
  },
  setPageSize(state: ShipmentsState, val: number) {
    state.pageSize = val;
  },
  editDelivery(state: ShipmentsState, { deliveryNumber, col, value }: DeliveryEditSpec) {
    // we are not changing the delivery itself, instead all edits are collected
    // - the 'getShipments' getter will combine the edits with the shipments for display in the delivery table
    // - for the API request, we also collect the DQNewStageEvent DTO.
    if (col.dqTeamEdit) {
      const delivery = state.shipments.find((d) => d.deliveryNumber === deliveryNumber);
      if (delivery) {
        const propertyName = col.property ? col.property : col.field;
        const currentFieldValue = delivery[propertyName];
        const changedValue = col.dqTeamEdit.changedFieldValue(currentFieldValue, value);
        if (!(deliveryNumber in state.shipmentEdits)) {
          state.shipmentEdits[deliveryNumber] = [];
        }
        const existing = state.shipmentEdits[deliveryNumber].findIndex((edit) => propertyName in edit.shipmentEdit);
        if (existing > -1) {
          state.shipmentEdits[deliveryNumber].splice(existing, 1);
        }
        state.shipmentEdits[deliveryNumber].push({
          shipmentEdit: { [propertyName]: changedValue },
          dqApiDto: col.dqTeamEdit.toDqApi(value)
        });
      }
    }
  },
  resetShipmentEdits(state: ShipmentsState) {
    state.shipmentEdits = {};
  },
  saveShipmentEdits(state: ShipmentsState, savedDeliveryIds: Array<string>) {
    savedDeliveryIds.forEach((id) => (state.savedShipmentEdits[id] = Date.now()));
  },
  beginFetching(state: ShipmentsState, controller: AbortController) {
    if (state.fetchingShipments && typeof state.fetchShipmentController.abort === 'function') {
      state.fetchShipmentController.abort();
    }
    state.fetchShipmentController = controller;
    state.fetchingShipments = true;
  },
  setSort(state, sort) {
    state.sort = sort;
  },
  setPage(state, page) {
    state.page = page;
  }
};

const actions = {
  FETCH_SHIPMENTS: async ({ commit }, payload: DeliverySummaryBodyWithPagination) => {
    const controller = new AbortController();
    commit('beginFetching', controller);
    const response = await deliverySummariesApi.deliverySummaries(payload as Pageable, payload as DeliverySummaryBody, {
      signal: controller.signal
    });

    if (response && response.data) {
      commit('setShipments', response.data);
    } else {
      if (!isCancel(response)) {
        if (response instanceof AxiosError) {
          const errorCode = response?.response?.status.toString() ?? '1801';
          commit(
            'showDialogAlert',
            {
              text: `There was an error when fetching the deliveries. Try changing the filter criteria.
          Error Code: ${errorCode}`
            },
            { root: true }
          );
        }
        commit('setShipmentsEmpty');
      }
    }
  },

  EXPORT_CSV: async ({ state }, payload: DeliverySummariesExportRequestBody) => {
    return await deliverySummariesApi
      .deliverySummariesExport({}, undefined, payload, { responseType: 'blob' })
      .then((resp) => {
        const data = resp.data as unknown as Blob; // we can force type here, because we set responseType: 'blob' above
        const url = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'VISTA_deliveries.csv');
        document.body.appendChild(link);
        link.click();
        link.remove();
        return true;
      })
      .catch((error) => {
        console.log('Error saving collection statistics:' + error);
        return false;
      });
  },

  FOLLOW_SHIPMENTS: async ({ commit }, payload: { followedDelivery: FollowedDelivery; status: boolean }) => {
    if (payload.status) {
      return await userApi
        .followDelivery(payload.followedDelivery)
        .then((resp) => {
          if (!resp || resp.status === 400) {
            commit('restoreFollowedDeliveries', payload.followedDelivery.logisticTrackerId, { root: true });
            return false;
          }
          commit('setFollowedDeliveries', resp.data, { root: true });
          return true;
        })
        .catch((error) => {
          console.log('Error toggling follow filter ' + error);
          return false;
        });
    } else if (payload.followedDelivery.logisticTrackerId) {
      return await userApi
        .unfollowDelivery(payload.followedDelivery.logisticTrackerId)
        .then((resp) => {
          commit('setFollowedDeliveries', resp?.data, { root: true });
          return true;
        })
        .catch((error) => {
          console.log('Error toggling unfollow filter ' + error);
          return false;
        });
    }
  },

  SAVE_EDITED_DELIVERIES: async ({ state, commit }) => {
    const dqApiChanges = [] as Array<DQNewStageEvent>;
    for (const [deliveryNumber, edits] of Object.entries(state.shipmentEdits) as Array<any>) {
      const delivery = state.shipments.find((d) => d.deliveryNumber === deliveryNumber);
      edits.forEach((edit) => {
        dqApiChanges.push({
          id: delivery.id,
          ...edit.dqApiDto
        });
      });
    }

    const saveResponse = await dqApi.addNewStageEvents(dqApiChanges);
    if (saveResponse && saveResponse.status === 200) {
      commit('saveShipmentEdits', saveResponse.data);
      commit('resetShipmentEdits');
    }
  }
};

export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};

export interface DeliverySummaryBodyWithPagination extends DeliverySummaryBody {
  page: number;
  size: number;
}
